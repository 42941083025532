<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        角色管理
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="角色狀態">
          <b-select
            :options="[
              { value: null, text: '全部' },
              { value: 'Y', text: '啟用' },
              { value: 'N', text: '未啟用' },
            ]"
            v-model="searchIsActive"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="角色代碼">
          <b-input v-model="searchId" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-input-group prepend="角色名稱">
          <b-input v-model="searchName" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="warning" @click="openModal(1)">新增角色</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="padding:0.5px !important">
        <b-button
          variant="primary"
          size="sm"
          @click="openModal(2, item)"
          class="mr-2"
          >編輯角色</b-button
        >
        <b-button
          variant="danger"
          size="sm"
          @click="deleteAccount(item)"
          class="mr-2"
          >刪除角色</b-button
        >
      </div>

      <div :title="item.ROLE_ID">
        {{ item.ROLE_ID || "" }}
      </div>
      <div :title="item.ROLE_NAME">
        {{ item.ROLE_NAME || "" }}
      </div>
      <div :title="item.ACTIVE">
        {{ item.ACTIVE == "N" ? "未啟用" : item.ACTIVE == "Y" ? "啟用" : "" }}
      </div>
      <div>
        {{ $twDate(item.UPDATE_ON) }}
      </div>
      <div :title="item.UPDATE_BY">
        {{ item.UPDATE_BY }}
      </div>
      <div>
        {{ $twDate(item.CREATE_ON) }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        class="mt-2"
        :options="rows"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <!-- forSaveData -->
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '編輯'}角色資料`"
        size="lg"
      >
        <div class="modal-grid-2">
          <b-input-group prepend="角色代碼">
            <b-input
              v-model="modalItem.ROLE_ID"
              :disabled="modalType == 2"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="角色狀態" class="two-column">
            <b-select
              :options="[
                { value: 'Y', text: '啟用' },
                { value: 'N', text: '未啟用' },
              ]"
              v-model="modalItem.ACTIVE"
            ></b-select>
          </b-input-group>
          <b-input-group prepend="角色名稱" class="one-row">
            <b-input v-model="modalItem.ROLE_NAME"></b-input>
          </b-input-group>
          <b-input-group prepend="角色功能" class="one-row">
            <treeselect
              :multiple="true"
              :options="options"
              :disable-branch-nodes="true"
              v-model="modalItem.FunctionIds"
              search-nested
            />
          </b-input-group>
        </div>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Swal from "sweetalert2";

const headersContent = [
  { name: "操作", key: "", sortDesc: null },
  { name: "角色代碼", key: "ROLE_ID", sortDesc: null },
  { name: "角色名稱", key: "ROLE_NAME", sortDesc: null },
  { name: "啟用狀態", key: "ACTIVE", sortDesc: null },
  { name: "更新日期", key: "UPDATE_ON", sortDesc: null },
  { name: "更新者", key: "UPDATE_BY", sortDesc: null },
  { name: "創建時間", key: "CREATE_ON", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersContent,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {
        ROLE_ID: "",
        ROLE_NAME: "",
        ROLE_DESC: "",
        CREATE_ON: "",
        CREATE_BY: "",
        UPDATE_ON: "",
        UPDATE_BY: "",
        ACTIVE: "",
        FunctionIds: [],
      },
      modalType: 1,

      //search
      searchIsActive: null,
      searchId: "",
      searchName: "",

      //searchOptions or ModalOptions

      testvalue: [],
      options: [
        {
          id: "我的專區",
          label: "我的專區",
          children: [
            {
              id: "60001",
              label: "個人資料維護",
            },
            {
              id: "60004",
              label: "偏護菁英分發志願選填",
            },
            {
              id: "60002",
              label: "密碼修改",
            },
            {
              id: "60003",
              label: "服務歷程",
            },
            {
              id: "電子表單",
              label: "電子表單",
              children: [
                {
                  id: "60008",
                  label: "分發訓練",
                },
                {
                  id: "60009",
                  label: "分發服務",
                },
              ],
            },
            {
              id: "大小手及問卷",
              label: "大小手及問卷",
              children: [
                {
                  id: "60010",
                  label: "大手故事集",
                },
                {
                  id: "60014",
                  label: "問卷調查",
                },
                {
                  id: "60005",
                  label: "問卷調查",
                },
              ],
            },
            {
              id: "下載專區",
              label: "下載專區",
              children: [
                {
                  id: "60006",
                  label: "下載專區檢視",
                },
                {
                  id: "60007",
                  label: "下載專區維護",
                },
              ],
            },
          ],
        },
        {
          id: "招生服務",
          label: "招生服務",
          children: [
            {
              id: "招生前置作業",
              label: "招生前置作業",
              children: [
                {
                  id: "10040",
                  label: "籍屬分配會議",
                },
                {
                  id: "10041",
                  label: "招生系別與名額維護",
                },
                {
                  id: "10042",
                  label: "三年內錄取名單",
                },
                {
                  id: "10043",
                  label: "離島甄試保送生",
                },
                {
                  id: "10044",
                  label: "三年內錄取名單上傳",
                },
                {
                  id: "10045",
                  label: "離島甄試保送生上傳",
                },
              ],
            },
            {
              id: "資格審查作業",
              label: "資格審查作業",
              children: [
                {
                  id: "10032",
                  label: "報考資格審查",
                },
                {
                  id: "10010",
                  label: "學測成績上傳",
                },
                {
                  id: "10033",
                  label: "籍屬身分審查",
                },
                {
                  id: "10034",
                  label: "報考資格審查(學士)",
                },
              ],
            },
            {
              id: "甄試作業",
              label: "甄試作業",
              children: [
                {
                  id: "10011",
                  label: "第一階段資格合格名單",
                },
                {
                  id: "10111",
                  label: "第一階段合格名單(學士)",
                },
                {
                  id: "10012",
                  label: "甄選成績上傳作業",
                },
                {
                  id: "10013",
                  label: "甄選學測成績排名查詢",
                },
                {
                  id: "10018",
                  label: "公費生成績查詢",
                },
                {
                  id: "10118",
                  label: "公費生成績查詢(學士)",
                },
              ],
            },
            {
              id: "分發報到作業",
              label: "分發報到作業",
              children: [
                {
                  id: "10014",
                  label: "志願分發結果",
                },
                {
                  id: "10015",
                  label: "甄選結果",
                },
                {
                  id: "10016",
                  label: "提交甄試入學委員會結果",
                },
                {
                  id: "10017",
                  label: "公費生志願查詢",
                },
                {
                  id: "10037",
                  label: "統一分發結果",
                },
              ],
            },
            {
              id: "10005",
              label: "公佈欄管理",
            },
            {
              id: "招生基本資料設定",
              label: "招生基本資料設定",
              children: [
                {
                  id: "10020",
                  label: "學校維護設定",
                },
                {
                  id: "10021",
                  label: "學校科系設定",
                },
                {
                  id: "10022",
                  label: "偏鄉籍定義設定",
                },
                {
                  id: "10023",
                  label: "成績計算參考設定",
                },
                {
                  id: "10024",
                  label: "籍屬身分確認開放時間",
                },
                {
                  id: "10025",
                  label: "面試日期設定",
                },
                {
                  id: "10026",
                  label: "成績公布日期設定",
                },
                {
                  id: "10027",
                  label: "填寫志願日期設定",
                },
              ],
            },
          ],
        },
        {
          id: "預算申請",
          label: "預算申請",
          children: [
            {
              id: "在地養成預算管理",
              label: "在地養成預算管理",
              children: [
                {
                  id: "20011",
                  label: "在地養成-經費申請",
                },
                {
                  id: "20031",
                  label: "在地養成-經費請領(撥付)",
                },
                {
                  id: "20012",
                  label: "在地養成-經費核銷",
                },
                {
                  id: "20015",
                  label: "在地養成-各科系註冊費設定",
                },
                {
                  id: "20016",
                  label: "在地養成-暑期課程學分費補助申請",
                },
                {
                  id: "20013",
                  label: "在地養成-經費預估(審核用)",
                },
                {
                  id: "20014",
                  label: "在地養成-經費撥付(審核用)",
                },
                {
                  id: "20017",
                  label: "在地養成-暑期課程學分費補助核定",
                },
              ],
            },
            {
              id: "偏護菁英預算管理",
              label: "偏護菁英預算管理",
              children: [
                {
                  id: "20021",
                  label: "偏護菁英-費用預估",
                },
                {
                  id: "20022",
                  label: "偏護菁英-費用申請",
                },
                {
                  id: "20023",
                  label: "偏護菁英-費用核銷",
                },
                {
                  id: "20027",
                  label: "偏護菁英各校註冊費設定",
                },
                {
                  id: "20024",
                  label: "偏護菁英-費用預審",
                },
                {
                  id: "20025",
                  label: "偏護菁英-費用核定",
                },
              ],
            },
            {
              id: "重點科別預算管理",
              label: "重點科別預算管理",
              children: [
                {
                  id: "20305",
                  label: "重點科別-預算預審",
                },
                {
                  id: "20302",
                  label: "重點科別-經費核銷",
                },
                {
                  id: "20306",
                  label: "重點科別-預算核定(審核用)",
                },
                {
                  id: "20301",
                  label: "重點科別-經費申請",
                },
                {
                  id: "20303",
                  label: "重點科別-各科系註冊費設定",
                },
                {
                  id: "20311",
                  label: "重點科別-暑期課程學分費補助申請",
                },
                {
                  id: "20312",
                  label: "重點科別-暑期課程學分費補助核定",
                },
              ],
            },
          ],
        },
        {
          id: "系統管理",
          label: "系統管理",
          children: [
            {
              id: "基本資料",
              label: "基本資料",
              children: [
                {
                  id: "5000C",
                  label: "學校資料管理",
                },
                {
                  id: "5000D",
                  label: "學校系別管理",
                },
                {
                  id: "5000E",
                  label: "籍屬身分管理",
                },
                {
                  id: "5000F",
                  label: "衛生單位管理",
                },
                {
                  id: "5000H",
                  label: "郵遞編號管理",
                },
                {
                  id: "5000I",
                  label: "招生學校代碼管理",
                },
                {
                  id: "5000J",
                  label: "在地養成服務單位及學校設置",
                },
                {
                  id: "5000K",
                  label: "偏護菁英-分發單位及學校設置",
                },
                {
                  id: "5000L",
                  label: "培訓單位管理",
                },
                {
                  id: "5000Z",
                  label: "系統代碼資料維護",
                },
                {
                  id: "5000M",
                  label: "重點科別培育計畫服務單位及學校設置",
                },
              ],
            },
            {
              id: "權限管理",
              label: "權限管理",
              children: [
                {
                  id: "50001",
                  label: "帳號管理",
                },
                {
                  id: "50020",
                  label: "權限管理",
                },
                {
                  id: "50003",
                  label: "功能管理",
                },
                {
                  id: "50005",
                  label: "稽核紀錄",
                },
                {
                  id: "5000G",
                  label: "角色資料維護",
                },
              ],
            },
            {
              id: "偏護菁英分發作業",
              label: "偏護菁英分發作業",
              children: [
                {
                  id: "50031",
                  label: "偏護菁英-畢業生資料",
                },
                {
                  id: "50033",
                  label: "偏護菁英-當年度畢業成績檢視",
                },
                {
                  id: "50034",
                  label: "偏護菁英-當年度分發結果",
                },
              ],
            },
            {
              id: "在地養成公費生資料維護",
              label: "在地養成公費生資料維護",
              children: [
                {
                  id: "5000S",
                  label: "在地養成-基本資料維護",
                },
                {
                  id: "50041",
                  label: "在地養成-報到",
                },
              ],
            },
            {
              id: "5000N",
              label: "偏護菁英公費生資料維護",
            },
            {
              id: "重點科別培育公費生資料維護",
              label: "重點科別培育公費生資料維護",
              children: [
                {
                  id: "50060",
                  label: "重點科別-基本資料維護",
                },
                {
                  id: "5000V",
                  label: "重點科別資料維護",
                }
              ],
            },
            {
              id: "專科護理師碩士公費生資料維護",
              label: "專科護理師碩士公費生資料維護",
              children: [
                {
                  id: "5000P",
                  label: "專科護理師碩士-基本資料維護",
                },
                //{
                //  id: "50071",
                //  label: "專科護理師碩士-報到",
                //},
              ],
            },
            {
              id: "一般公費醫師制度公費生資料維護",
              label: "一般公費醫師制度公費生資料維護",
              children: [
                {
                  id: "50080",
                  label: "一般公費醫師制度-基本資料維護",
                }
              ],
            },
            {
              id: "5000A",
              label: "統計指標",
            },
          ],
        },
        {
          id: "課程分享",
          label: "課程分享",
          children: [
            {
              id: "在校課程",
              label: "在校課程",
              children: [
                {
                  id: "70010",
                  label: "在校課程平台播放",
                },
                {
                  id: "70011",
                  label: "在校課程平台維護",
                },
              ],
            },
            {
              id: "培訓課程",
              label: "培訓課程",
              children: [
                {
                  id: "70020",
                  label: "培訓課程播放",
                },
                {
                  id: "70021",
                  label: "培訓課程維護",
                },
              ],
            },
          ],
        },
        {
          id: "服務管理",
          label: "服務管理",
          children: [
            {
              id: "服務歷程管理",
              label: "服務歷程管理",
              children: [
                {
                  id: "40031",
                  label: "在地養成公費生服務歷程",
                },
                {
                  id: "40032",
                  label: "偏護菁英公費生服務歷程",
                },
                {
                  id: "30012",
                  label: "PGY資料上傳",
                },
                 {
                  id: "40036",
                  label: "重點科別培育公費生",
                },
              ],
            },
            {
              id: "偏護菁英分發醫院管理",
              label: "偏護菁英分發醫院管理",
              children: [
                {
                  id: "40010",
                  label: "偏護菁英分發醫院管理",
                },
                {
                  id: "40011",
                  label: "偏護菁英分發醫院志願提列員額",
                },
                {
                  id: "40012",
                  label: "偏護菁英分發醫院志願核定員額",
                },
              ],
            },
            {
              id: "分發申請書",
              label: "分發申請書",
              children: [
                {
                  id: "40014",
                  label: "分發訓練審核",
                },
                {
                  id: "40015",
                  label: "分發服務審核",
                },
              ],
            },
            {
              id: "40007",
              label: "公告管理",
            },
            {
              id: "衛生所缺額管理",
              label: "衛生所缺額管理",
              children: [
                {
                  id: "40040",
                  label: "衛生所缺額管理(附件一)",
                },
                {
                  id: "40041",
                  label: "衛生所缺額管理(附件二)",
                },
                {
                  id: "40042",
                  label: "衛生所缺額管理(附件三)",
                },
                {
                  id: "40043",
                  label: "衛生所缺額管理(附件四)",
                },
                {
                  id: "40044",
                  label: "衛生所缺額管理(附件五)",
                },
                {
                  id: "40045",
                  label: "檔案上傳",
                },
                {
                  id: "40046",
                  label: "檔案下載",
                },
              ],
            },
            {
              id: "40009",
              label: "衛生所缺額調查表",
            },
          ],
        },
        {
          id: "輔導專區",
          label: "輔導專區",
          children: [
            {
              id: "在地養成輔導委辦單位",
              label: "在地養成輔導委辦單位",
              children: [
                {
                  id: "90011",
                  label: "在地養成大手故事集管理",
                },
                {
                  id: "90012",
                  label: "在地養成輔導紀錄",
                },
                {
                  id: "90013",
                  label: "在地養成問卷統計",
                },
                {
                  id: "90014",
                  label: "衛生所資訊管理",
                },
                {
                  id: "90015",
                  label: "Q&A管理",
                },
                {
                  id: "90016",
                  label: "本年度見面會資訊管理",
                },
                {
                  id: "90017",
                  label: "資源地圖問卷管理",
                },
              ],
            },
            {
              id: "偏護菁英輔導委辦單位",
              label: "偏護菁英輔導委辦單位",
              children: [
                {
                  id: "90021",
                  label: "偏護菁英輔導紀錄",
                },
                {
                  id: "90022",
                  label: "偏護菁英總輔導紀錄",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  components: {
    Treeselect,
  },
  computed: {},

  methods: {
    clearSearch() {
      this.searchIsActive = null;
      this.searchId = "";
      this.searchName = "";
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },

    async resetPassword(item) {
      const url = `SysUser/ResetPassword?account=${item.Account}`;

      let result = await Swal.fire({
        title: "",
        html: `<section class='align-left'"><div>是否確定要重置密碼?</div><div>密碼重置為: ${item.Account}</div></section>`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      });
      if (result.isConfirmed) {
        await window.axios.put(url);
        await this.getData();
        Swal.fire("角色密碼重置成功", "", "success");
      }
    },
    async deleteAccount(item) {
      const url = `api/SysRole/${item.ROLE_ID}`;

      let result = await Swal.fire({
        title: "",
        html: `<section class='align-left'"><div>是否確定要刪除此角色?</div><div>${item.ROLE_ID} ${item.ROLE_NAME}</div></section>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      });
      if (result.isConfirmed) {
        await window.axios.delete(url);
        await this.getData();
        Swal.fire("角色刪除成功", "", "success");
      }
    },
    async openModal(type, item) {
      this.modalType = type;

      if (type == 1) {
        this.modalItem = {
          ROLE_ID: "",
          ROLE_NAME: "",
          ROLE_DESC: "",
          CREATE_ON: this.$moment(new Date()).format("YYYY-MM-DD"),
          CREATE_BY: `${window.sessionStorage.docrkkt}`.replace(/\"/g, ""),
          UPDATE_ON: this.$moment(new Date()).format("YYYY-MM-DD"),
          UPDATE_BY: `${window.sessionStorage.docrkkt}`.replace(/\"/g, ""),
          ACTIVE: "Y",
          FunctionIds: [],
        };
      } else {
        this.modalItem = { ...item };
        this.modalItem.UPDATE_BY = `${window.sessionStorage.docrkkt}`.replace(
          /\"/g,
          ""
        );
        this.modalItem.UPDATE_ON = this.$moment(new Date()).format(
          "YYYY-MM-DD"
        );
      }

      this.$bvModal.show("editModal");
    },
    async saveModal() {
      const url = `api/SysRole`;
      const obj = {
        ...this.modalItem,
      };
      try {
        this.modalType == 1
          ? await window.axios.post(url, obj)
          : await window.axios.put(url, obj);
        this.$bvToast.toast(
          `系統角色${this.modalType == 1 ? "新增" : "編輯"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      let bQs = false;

      if (this.searchIsActive) {
        qs = this.$equalSubstringofFilter("ACTIVE", qs, this.searchIsActive);
        bQs = true;
      }
      if (this.searchId) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("ROLE_ID", qs, this.searchId);
        } else {
          qs = this.$equalSubstringofFilter("ROLE_ID", qs, this.searchId);
        }
        bQs = true;
      }
      if (this.searchName) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("ROLE_NAME", qs, this.searchName);
        } else {
          qs = this.$equalSubstringofFilter("ROLE_NAME", qs, this.searchName);
        }
        bQs = true;
      }

      try {
        let { Items, Count } = await window.axios.get(`api/SysRole${qs}`);

        this.items = JSON.parse(JSON.stringify(Items));
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    // async getSearchOptions() {
    //   const { Items } = await window.axios.get("api/SysRole");
    //   let uniqueArr = this.$dedup(JSON.parse(JSON.stringify(Items)));
    //   uniqueArr = uniqueArr.filter((s) => s.ACTIVE == "Y");
    //   this.roleOptions = JSON.parse(JSON.stringify(uniqueArr));

    //   let { Items: items2 } = await window.axios.get("api/SysRole");
    //   items2 = items2.map((s) => s.HealthName);
    //   let uniqueArr2 = [...new Set(items2)];
    //   uniqueArr2.sort();
    //   this.healthNameOptions = JSON.parse(JSON.stringify(uniqueArr2));
    // },
  },
  async mounted() {
    // await this.getSearchOptions();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    "modalItem.isForever"(v) {
      if (v) {
        this.modalItem.IsActive = true;
        this.modalItem.LastActivityDate = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 257px 210px 300px 210px 210px 210px 210px;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    padding-top: 5px !important;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 34px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(7, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 353px 104px 1fr;
  grid-gap: 10px;
  .one-row {
    grid-column: 1/-1;
  }
  .two-column {
    grid-column: 2/-1;
  }
}
.flex-box {
  display: flex;
  flex-wrap: wrap;
  .item-box {
    margin: 5px;
  }
}
</style>
